var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar-header d-xl-block d-none" }, [
    _c("ul", { staticClass: "nav navbar-nav" }, [
      _c(
        "li",
        { staticClass: "nav-item" },
        [
          _c("b-link", { staticClass: "navbar-brand", attrs: { to: "/" } }, [
            _c(
              "span",
              { staticClass: "brand-logo" },
              [_c("b-img", { attrs: { src: _vm.appLogoImage, alt: "logo" } })],
              1
            ),
            _c("h2", { staticClass: "brand-text mb-0" }, [
              _vm._v("\n          " + _vm._s(_vm.appName) + "\n        "),
            ]),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }